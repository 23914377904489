import "./Media.scss";

function Media() {
    return(
        <>
        <div className="media">
            <h1 className="media__heading">Media Page</h1>

            <div className="media__container">
                
            </div>

        </div>
        </>
    )
}

export default Media;