import "./Home.scss";

function Home() {
    return(
        <>
        <div className="home">
        <div className="overlay">


        </div>
        </div>
        </>
    )
}

export default Home;